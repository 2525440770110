<template>
  <div class='output popUpScrollList jAnnouncementScreen'>
    <div v-if="announcements.length===0" class='element materialShadow'>
      <div class='emptyList'>
        <i class='fa fa-exclamation-circle'></i><br>{{ x('emptyAnnouncementList') }}
      </div>
    </div>
    <announcement-item v-for="announcement in announcements"
                       :announcement="announcement" :key="announcement.id"></announcement-item>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpGet } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';
  import AnnouncementItem from './AnnouncementItem';

  /**
   * This component is used in the app to show a list of announcements for the logged in user.
   */
  export default {
    name: 'announcements-list',
    mixins: [translate],
    components: {
      'announcement-item': AnnouncementItem,
    },
    emits: [ComponentEventNames.didLoad],
    props: {
      initialAnnouncements: {
        type: Array,
      },
    },
    data() {
      return {
        isLoading: true,
        announcements: this.initialAnnouncements || [],
      };
    },
    computed: mapState(['config', 'user']),
    async mounted() {
      if (this.initialAnnouncements === undefined) {
        await this.loadAnnouncements();
      }
      this.$emit(ComponentEventNames.didLoad);
    },
    methods: {
      async loadAnnouncements() {
        this.announcements = await httpGet('users/me/announcements/read');
      },
    },
  };
</script>
